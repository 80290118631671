import {toAbsoluteUrl} from '../../_metronic/helpers'

// meta tags
export const metaTags_data = {
  title: 'Monoliths',
  siteName: 'Monoliths',
  description:
    'Monoliths helps you see the potential of investing. Go ahead, explore your possibilities.',
  logo: 'frontend/public/monolith-logo.svg',
  // Vividli
  Vividli_title: 'Vividli',
  Vividli_siteName: 'Vividli',
  Vividli_description:
    'Vividli helps you see the potential of investing. Go ahead, explore your possibilities.',
}

// footer section
export const FOOTER_DATA = {
  // text
  JOIN_TEAM: 'Join the Team',
  CONTACT_US: 'CONTACT US',
  MISSION: `We're on a mission, and are seeking like-minded customers, engineers, designers, advisors, allies, investors, and friends. Email us a note!`,
  ONYXTEC_CREDIT: 'Site built with love by',
  ONYXTEC: 'OnyxTec',
  LEGAL_NOTES_FIRST_TITLE: 'Here are some legal notes:',
  LEGAL_NOTES_SECOND_TITLE: 'Here are some more legal notes:',
  ABOUT_MONOLITHS:
    'Monoliths.io is a visualization technology startup based in Seattle, WA, USA. We are seeking customer feedback on our products. This page is for informational purposes only – it is not financial nor investment advice. We are not a financial advisor nor fiduciary. (Nor do we play one on tv.) Everyone’s situation is different, so please consult with an accountant, fiduciary, or advisor. Joining the waitlist enables us to be in touch with you for future feedback, testing, and such opportunities related to our products. We keep your responses confidential. We use your responses to improve our products, enable building our business, to communicate with you, and to provide you future opportunities and services. We may regularly update this page based on feedback.  We value good corporate governance. Visuals used on this page are either original to Monoliths.io or permitted for open-use by Canva.com, or Figma. \n*Returns of Ethereum (ETH), a cryptocurrency, can fluctuate rapidly. We use an average of 20% to help in comparison, but could change fast and is not guaranteed. Please do further research or consult with an advisor about ETH. \n**ARQ Fix and Flip RoR is not annualized, because it is a one-time event.\n',
  VIVIDLI_ABOUT_MONOLITHS:
    'Vividli.ai (formerly aka Monoliths.io) is a visualization technology startup based in Seattle, WA, USA. We are seeking customer feedback on our products. This page is for informational purposes only – it is not financial nor investment advice. We are not a financial advisor nor fiduciary. (Nor do we play one on tv.) Everyone’s situation is different, so please consult with an accountant, fiduciary, or advisor. Joining the waitlist enables us to be in touch with you for future feedback, testing, and such opportunities related to our products. We keep your responses confidential. We use your responses to improve our products, enable building our business, to communicate with you, and to provide you future opportunities and services. We may regularly update this page based on feedback.  We value good corporate governance. Visuals used on this page are either original to Vividli.ai or permitted for open-use by Canva.com, or Figma. \n*Returns of Ethereum (ETH), a cryptocurrency, can fluctuate rapidly. We use an average of 20% to help in comparison, but could change fast and is not guaranteed. Please do further research or consult with an advisor about ETH. \n**ARQ Fix and Flip RoR is not annualized, because it is a one-time event.\n',

  LEGAL_NOTES_PARAGRAPH:
    "This page is a rough illustration of a product concept. It is not financial advice, nor offer for investment. Let's talk about risks. All investing has risk of loss. Historic performance is no guarantee of future returns.",
  DISCLAIMER:
    'Disclaimer: The information on this website is for general informational purposes only and does not constitute investment advice or a recommendation to buy or sell any securities or other financial products. The calculator service is an automated online tool based on your goals, risk tolerance and time horizon. The calculator service does not guarantee any specific results or returns, and past performance is not indicative of future performance. Investing involves risk, including the possible loss of principal. You should consult a qualified financial professional before making any investment decisions. The calculator service is not responsible for any errors or omissions in the information provided on this website or for any damages arising from the use or reliance on this information',
  // images
  IMAGES: {
    ONYXTEC_LOGO: toAbsoluteUrl('/media/home/logo_onyxtec.png'),
    FOOTER_BIG_IMG: toAbsoluteUrl('/media/home/footer-big-img.svg'),
    FOOTER_CLOUD_BOTTOM: toAbsoluteUrl('/media/home/footer-cloud-bottom.svg'),
    FOOTER_MOB_MOUNTAINS: toAbsoluteUrl('/media/home/footer-mob.svg'),
  },
}

// financial section
export const FINANCIAL_SECTION = {
  // text
  FINANCIALLY_FREE: 'What if every human on Earth was financially free?',
  FULL_POTENTIAL: 'They can realize their full potential. We’re building the door. Join us!',
  FREEDOM:
    'Imagine the freedom to pursue your passions, create lasting memories with loved ones and live life on your own terms.',
  PEACE: 'Feel the peace of mind that comes with financial security.',
  MISSION:
    'Our mission is to empower you on your journey towards financial freedom. Join us and discover the transformative power of investing to create a life of',
  ABUNDANCE: "abundance, freedom, and fulfillment. Together, let's redefine what's possible!",
  DISCORD_LINK: toAbsoluteUrl('https://discord.gg/BZ6BC4dE4'),
  CONTACT_US_EMAIL: 'hello@monoliths.io',
  // images
  IMAGES: {
    CLOUD_WHITE: toAbsoluteUrl('/media/home/financial-cloud-white.svg'),
    FADE_CLOUD_RIGHT_SMALL: toAbsoluteUrl('/media/home/fi-fade-cloud-right-small.svg'),
    FADE_CLOUD_RIGHT_LARGE: toAbsoluteUrl('/media/home/fi-fade-cloud-big-right.svg'),
    EMAIL_ICON: toAbsoluteUrl('/media/home/email.svg'),
    REDDIT_ICON: toAbsoluteUrl('/media/home/reddit.svg'),
    DISCORD_ICON: toAbsoluteUrl('/media/home/discord.svg'),
    RED_BIRD_LEFT: toAbsoluteUrl('/media/home/red-bird.svg'),
    RED_BIRD_RIGHT: toAbsoluteUrl('/media/home/red-bird-right.svg'),
    LINE_RIGHT: toAbsoluteUrl('/media/home/lines-right.svg'),
    LINE_LEFT: toAbsoluteUrl('/media/home/lines-left.svg'),
    BIRD1: toAbsoluteUrl('/media/home/bird1.svg'),
    BIRD2: toAbsoluteUrl('/media/home/bird2.svg'),
    BIRD3: toAbsoluteUrl('/media/home/bird3.svg'),
    BIRD4: toAbsoluteUrl('/media/home/bird4.svg'),
    BIRD5: toAbsoluteUrl('/media/home/bird5.svg'),
    BIRD6: toAbsoluteUrl('/media/home/bird6.svg'),
    BIRD7: toAbsoluteUrl('/media/home/bird7.svg'),
    BIRD8: toAbsoluteUrl('/media/home/bird8.svg'),
    BIRD9: toAbsoluteUrl('/media/home/bird9.svg'),
    BIRD10: toAbsoluteUrl('/media/home/bird10.svg'),
    BIRD11: toAbsoluteUrl('/media/home/bird11.svg'),
    BIRD12: toAbsoluteUrl('/media/home/bird12.svg'),
    BIRD13: toAbsoluteUrl('/media/home/bird13.svg'),
    BIRD14: toAbsoluteUrl('/media/home/bird14.svg'),
    BIRD15: toAbsoluteUrl('/media/home/bird15.svg'),
    BIRD16: toAbsoluteUrl('/media/home/bird16.svg'),
  },
}

// stay tuned section
export const STAYTUNED_SECTION = {
  // text
  STAY_TUNED: 'Stay tuned for the full experience',
  IMAGINE: 'Imagine your garden coming to life. We’d love for you to try it.',
  SAVE_TIME: 'Save Time',
  NO_NEED: 'No need to become an expert.',
  CONFIDENT: 'Be Confident',
  HARD_MATH: 'No hard math or graphs, use your intuition.',
  GET_RETURNS: 'Get Returns',
  INVESTING: 'Investing could help give extra earnings to upgrade your lifestyle.',
  JOIN_WAITLIST: 'JOIN WAITLIST',
  // images
  IMAGES: {
    CLOUD_RIGHT: toAbsoluteUrl('/media/home/stay-tuned-cloud-right.svg'),
    CLOUD_LEFT: toAbsoluteUrl('/media/home/stay-tuned-cloud-left.svg'),
    ELLIPSE_RIGHT: toAbsoluteUrl('/media/home/stay-tuned-ellipse-right.svg'),
    ELLIPSE_LEFT: toAbsoluteUrl('/media/home/stay-tuned-ellipse-left.svg'),
    PATH_RIGHT: toAbsoluteUrl('/media/home/stay-tuned-path-right.svg'),
    PATH_LEFT: toAbsoluteUrl('/media/home/stay-tuned-path-left.svg'),
  },
}

// beginners section
export const BEGINNERS_SECTION = {
  // text
  WE_DO: 'We do this for beginners',
  VISUALIZATION: 'We’re a visualization startup based in Seattle, Washington.',
  OUR_MISSION:
    'Our mission is to make every human on Earth financially free, so they can realize their full potential. But investing is intimidating, especially for beginners. We were there too. We hope this visual calculator helps you unlock your investing journey. With confidence. One investment at a time.',
  MONOLITH_HELP:
    'Using Monoliths helps more and more people get closer to  realizing their dreams.',
  VIVIDLI_MONOLITH_HELP:
    'Using Vividli helps more and more people get closer to  realizing their dreams.',

  OUR_VALUES: 'Our Values',
  // images
  IMAGES: {
    CLOUD_SMALL: toAbsoluteUrl('/media/home/beginners-cloud-small.svg'),
    CLOUD_LARGE: toAbsoluteUrl('/media/home/beginners-cloud-big.svg'),
    WATERING_PLANTS: toAbsoluteUrl('/media/home/watering-plants.svg'),
    VALUES_BAG: toAbsoluteUrl('/media/home/bags-values.svg'),
  },
}

// same math new look section
export const SAME_MATH_SECTION = {
  // text
  SAME_MATH: 'Same math, new look',
  POPULAR_INVESTMENTS: 'Popular investments',
  HELP: 'We picked ones you may have heard about. We put them side by side to help you compare, taking out the hype.',
  STANDARD_FORMULA: 'Standard formulas',
  FINANCIAL_FORMULA: 'We use typical returns and standard financial formulas, such as compounding.',
  SHOWING_RISK: 'Showing risk',
  PREDICT: `Monolith's can't predict the future. Not even AI can. So we show our calculations in ranges, and will soon try to convey risk as well. Generally, high-risk, high-return!`,
  VIVIDLI_PREDICT: `Vividli can't predict the future. Not even AI can. So we show our calculations in ranges, and will soon try to convey risk as well. Generally, high-risk, high-return!`,

  // images
  IMAGES: {
    WATERING_CAN: toAbsoluteUrl('/media/home/watering-can.svg'),
    CALC_BIRD: toAbsoluteUrl('/media/home/calc-bird.svg'),
    MAG_GEARS: toAbsoluteUrl('/media/home/mag-gears.svg'),
  },
}

// lifestyle section
export const LIFESTYLE_SECTION = {
  // text
  LIFE_STYLE: 'Upgrade your lifestyle',
  INVESTING:
    "Investing could unlock a world of possibilities. It can give you the means to make your dreams a reality. Whether it's traveling the world, buying your dream home, or providing for your loved ones, investing empowers you to create the life you've always envisioned.",
  INTIMIDATING:
    'But, investing can be intimidating. So we are making reasoning about it easy and intuitive. Take charge of your financial future by planting your garden of investment today!',
  // images
  IMAGES: {
    BIRD_HOUSE: toAbsoluteUrl('/media/home/full-bird-house.svg'),
    CLOUD_TOP_SMALL: toAbsoluteUrl('/media/home/lifestyle-cloud-top-small.svg'),
    CLOUD_TOP: toAbsoluteUrl('/media/home/lifestyle-cloud-top.svg'),
    CLOUD_LEFT_SMALL: toAbsoluteUrl('/media/home/lifestyle-cloud-left-small.svg'),
    CLOUD_LEFT_LARGE: toAbsoluteUrl('/media/home/lifestyle-cloud-left-large.svg'),
    CLOUD_RIGHT_SMALL: toAbsoluteUrl('/media/home/lifestyle-cloud-right-small.svg'),
    CLOUD_RIGHT_LARGE: toAbsoluteUrl('/media/home/lifestyle-cloud-right-large.svg'),
  },
}

// investment section
export const INVESTMENT_SECTION = {
  // text
  INVESTMENT: 'Your investment garden starts with a click',
  POPULAR_SERVICES:
    'We feature popular services that strive to serve people who are new to investing. Click on their links below to find out more.',
  VIVIDLI_POPULAR_SERVICES:
    'We list these popular services that strive to serve people who are new to investing. Click on their links below to find out more.',
  COMMISSION_FORM: `You can also read more about investing <a target='_blank' href="https://www.investopedia.com/articles/basics/11/3-s-simple-investing.asp">here.</a> We may get a commission from them at no extra cost to you. Thank you – that will help us continue our mission to inspire people to take steps for their financial freedom.`,
  // images
  IMAGES: {
    ROCK_TREES: toAbsoluteUrl('/media/home/rocks-trees.svg'),
  },
  // services partner array
  SERVICES_PARTNERS: [
    {
      id: 1,
      image: toAbsoluteUrl('/media/home/acorns.svg'),
      link: 'https://www.acorns.com/',
    },
    {
      id: 2,
      image: toAbsoluteUrl('/media/home/betterment.svg'),
      link: 'https://www.betterment.com/',
    },
    {
      id: 3,
      image: toAbsoluteUrl('/media/home/fidelity.svg'),
      link: 'https://www.fidelity.com/',
    },
    {
      id: 4,
      image: toAbsoluteUrl('/media/home/wealth-simple.svg'),
      link: 'https://www.wealthsimple.com/en-ca',
    },
    {
      id: 5,
      image: toAbsoluteUrl('/media/home/stash.svg'),
      link: 'https://www.stash.com/',
    },
  ],
}

// hero section
export const HERO_SECTION = {
  // text
  VISUAL_INVESTING: 'Visual Investing - Plant Concept',
  WORK_IN_PROGRESS: 'Work in Progress',
  CALCULATOR:
    'Monoliths helps you see the potential of investing. Go ahead, explore your possibilities.',
  VIVIDLI_CALCULATOR: 'We make investing vivid.',
  JOIN_WAITLIST: 'Join us on the journey!',
  CALCULATOR_MOBILE: 'Monoliths helps you see the potential of investing.',
  EXPLORE: 'Explore',

  ARQ_HEADER: 'See how investing in ARQ could grow for you',
  ARQ_SUB_HEADING:
    'Salaams! See how ARQ compares. Hit Meet or Reply with any questions. - Aniq, CEO, ARQ Ventures',
  // images
  IMAGES: {
    LOGO_IMAGE: toAbsoluteUrl('/monolith-logo.svg'),
    HERO_IMAGE: toAbsoluteUrl('/media/home/hero-image.svg'),
    TOP_CLOUD: toAbsoluteUrl('/media/home/hero-mob-top-cloud.svg'),
    BOTTOM_CLOUD: toAbsoluteUrl('/media/home/hero-mob-bottom-cloud.svg'),
    MID_CLOUD: toAbsoluteUrl('/media/home/hero-mob-mid-cloud.svg'),
    HILLS: toAbsoluteUrl('/media/home/hero-mob-hills-trees.svg'),
    MOUNTAINS: toAbsoluteUrl('/media/home/hero-mob-mountains.svg'),
    EXPLORE_ARROW: toAbsoluteUrl('/media/home/explore-arrow.svg'),
  },
}

// projections section
export const PROJECTIONS_SECTION = {
  // text
  PROJECTIONS: 'Projections',
  STARTING_AMOUNT: 'Starting Amount',
  RETURN_RATE: 'Rate of Return',
  YEARS_TO_GROW: 'Years to Grow',
}

export const PROJECTIONS_CHART = {
  IMAGES: {
    STEM_IMAGE: toAbsoluteUrl('/media/home/stem-chart.svg'),
    CONTRIBUTIONS_IMAGE: toAbsoluteUrl('/media/home/plants/contributions.svg'),
    GAIN_IMAGE: toAbsoluteUrl('/media/home/plants/gain.svg'),
  },
}

// custom bar
export const CUSTOM_BAR = {
  // text
  TITLE: 'Add your own custom investment',
}

// coming soon section
export const FEATURE_COMING_SOON = {
  // text
  TITLE: 'Feature Coming Soon: Join Our Waitlist for Exclusive Early Access!',
  MESSAGE:
    'Thank you for your interest. We are still working on this feature. Please join our waitlist to be among the first to get this feature when ready!',
}

// input tool tip section
export const INPUT_BOX_TOOLTIPS = {
  // text
  STARTING_AMOUNT:
    'How much you want to start with? Usually, the more the better, since your money can grow more, and faster, the sooner you start investing.',
  CONTRIBUTIONS:
    'How much can you invest regularly? Think of this like savings - how much and often you save can make your savings grow faster.',
  EXTRA_CONTRIBUTIONS:
    'How much can you invest regularly? Think of this like savings - how much and often you save can make your savings grow faster. \nSame investments, like ARQ, do not take these extra contributions.',
  ARQ_FIELDS:
    "Expand this section to make changes to ARQ's investments, such as the amount of rent it makes, value of the property, and profit from selling it.\nThe default numbers you see here are an example of a typical ARQ investment, and are set by ARQ.",
  RATE_OF_RETURN:
    'Different investments can grow at different rates. Usually, lower-risk investments like banks or high-yield cash accounts grow slower, and higher-risk ones like stocks and crypto grow faster. These rates of return can vary over time with the economy. The rates we use here are typical recently (and for stocks, historically) for these investment types. \nBut, remember, rate of returns are not guaranteed. Past performance does not guarantee future results.',
  YEARS_TO_GROW:
    "The longer you are invested, the more likely you are to see investment gains. Time lets you start small and build up. That's because your money had more time to 'work for you.' When you invest for shorter-terms, you may have to put more money in yourself, go for higher-risk investments, or both.",
  END_OF_SENTENCE_FV:
    "We used the simple, standard, 'future value' formula to project what your investment might look like. Some investments can lose value over time because of inflation or fees. (Inflation is eating cash nowadays!) While nothing is guaranteed in investing - and just because an investment did really well in the past doesn't mean it must keep doing well in the future - generally, most investments grow if you hold them over time. For custom investments, we use what the investment provided",
  // images
  IMAGES: {
    TOOLTIP_ICON: toAbsoluteUrl('/media/icons/duotune/general/tooltip.svg'),
  },
}

// contact us page
export const CONTACT_US = {
  // text
  CONTACT: 'Contact Us',
  // images
  IMAGES: {
    LOGO_IMAGE: toAbsoluteUrl('/monolith-logo.svg'),
    BACK_ICON: toAbsoluteUrl('/media/icons/duotune/arrows/arr074.svg'),
  },
}

export const ADVISORS = {
  HEADING: 'Show your customers how investing could grow for them',
  STARTING_AMOUNT:
    'How much you want to start with? Usually, the more the better, since your money can grow more, and faster, the sooner you start investing.',
  CONTRIBUTIONS:
    'How much can you invest regularly? Think of this like savings - how much and often you save can make your savings grow faster.',
  RATE_OF_RETURN:
    'Which investment do you want the customer to focus on when they first see the chart?',
  YEARS_TO_GROW:
    "The longer you are invested, the more likely you are to see investment gains. Time lets you start small and build up. That's because your money had more time to 'work for you.' When you invest for shorter-terms, you may have to put more money in yourself, go for higher-risk investments, or both.",
  CUSTOM_EMAIL: 'Enter email address',
  PROPERTY_WORTH:
    'The total value of the real estate property. This is like what you might see if you go to Redfin or Zillow, for example.',
  CONTRIBUTIONS_LABEL:
    'Dividends What cash the customer regularly get? Such as rental income, or stock dividends.',
  CUSTOM_ROR_TOOLTIP:
    'What is the RoR you advertise or target, based on your models? (Leave blank to auto-calculate.)',
  CUSTOM_INV_NAME_TOOLTIP: 'What name do your customers use to refer to this investment?',
  CUSTOM_INV_TOOLTIP: `Specify your own investment`,
  REMOVE_CUSTOM_INV_TOOLTIP: 'Remove this custom field',
  CUSTOM_ROR: 'Select custom rate of return',
  LUMP_SUM_GAINS_TOOTTIP: 'Such as profits from a sale, flip/',
  APPRECIATION_GAIN_TOOLTIP: `What is the FV of the expected appreciation for this customer's investment`,
  DIVIDENDS_TOOLTIP:
    'What cash does the customer regularly get? Such as rental income, stock dividends.',
  GREETINGS_TOOLTIP:
    "Write a message to your customer! Please don't include any private or sensitive information here though.",
  NOTES_TOOLTIP: 'Jot something down',
  LOGO_TOOLTIP: "This let's you brand the page. Pick a high-res logo as .jpg, or .png",
  POWERED_BY_MESSAGE: 'Powered by Monoliths Visualization Service',
  VIVIDLI_POWERED_BY_MESSAGE: `Powered by Vividli's Monoliths visualization service`,
}

export const PROJECTIONS_TABLE = {
  TITLE: 'Here are the numbers',
  TIME_YEAR: 'Year',
  TIME_MONTH: 'Month',
  INITIAL_DEPOSIT: 'Initial Deposit',
  CONTRIBUTIONS: 'Contribution',
  TOTAL_CONTRB: 'Total Contribution',
  GAINS: 'Gain',
  TOTAL_GAINS: 'Total Gain',
  TOTAL: 'Estimated Total',
  CUSTOM_ROR: 'Annualized RoR',
  FUTURE_VALUE: 'Total Value',
  PROFIT_PHASE_1: 'Profit from Exit',
  RENTAL_INCOME: 'Rental Income',
  ROI: 'ROI',
  CASH_ON_CASH: 'Cash on Cash',
  TOTAL_ROI: 'Total ROI',
  TOTAL_CASH_ON_CASH: 'Total Cash on Cash',
}

export const TSBG = {
  title: `Here's the chart`,
}

export const TYPES_OF_ROR = {
  TYPE_SIMPLE: 'Simple RoR',
  TYPE_ARQ_FLIP: 'ARQ Fix & Flip (Legacy)',
  TYPE_ARQ_RENTAL: 'ARQ Rental (Legacy)',
  TYPE_BEFORE_EXIT: 'ARQ Before Exit',
  TYPE_AFTER_EXIT: 'ARQ After Exit',
}

export const PICTOGRAPH_SECTION = {
  MAX_LIMIT: 40,
  IMAGES: {
    TRIANGLE: toAbsoluteUrl('/media/home/triangle-svgrepo-com.svg'),
    CIRCLE: toAbsoluteUrl('/media/home/circle-svgrepo-com.svg'),
    PEN: toAbsoluteUrl('/media/home/square-pen-svgrepo-com.svg'),
  },
}

export const sectionIds = [
  'projections', //0
  'projections-chart', //1
  'projections-table', //2
  'perspective', //3
  'investments', //4
  'upgrade-your-lifestyle', //5
  'same-math-new-look', //6
  'about', //7
  'stay-tuned', //8
  'financial-freedom', //9
  'contact', //10
  'footer', //11
]
